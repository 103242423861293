@import "~antd/dist/antd.css";
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f3f3f3;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.ant-layout {
  background-color: transparent !important;
}

.ant-page-header {
  padding-bottom: 0;
  margin-bottom: 1rem;
  background-color: white;
}

.ant-row-middle > .ant-col,
.ant-row-middle > .ant-form,
.ant-row-center > .ant-space-vertical {
  background-color: white;
  padding: 0 1.5rem;
  margin: 1rem 0;
  width: 100%;
}

.ant-row-center > .ant-space-vertical,
.ant-row-middle > .ant-form {
  padding: 1.5rem;
}

button,
.ant-row-middle > .ant-col,
.ant-row-middle > .ant-form,
.ant-row-center > .ant-space-vertical,
.ant-input,
.ant-input-password {
  border-radius: 0.25rem !important;
}

.ant-form-item-control-input-content {
  column-gap: 0.5rem;
}

button.svg {
  display: grid;
  align-items: center;
  border: none;
  background-color: transparent;
  padding: 0.5rem;
}

/* scrollable table content for ease of use on tablet view */
@media only screen and (max-width: 1550px) {
  .ant-table-content {
    overflow-x: auto;
  }

  .ant-table-cell {
    white-space: nowrap;
  }
}

/* history page style + form container */

.form-container,
.history {
  width: 90%;
  max-width: 900px;
  margin: 0 auto;
  margin-bottom: 1rem;
}

.history-title {
  width: 90%;
  max-width: 900px;
  font-size: 1.5rem !important; /* match form title size */
}

.history.nav {
  justify-content: space-between;
}

.history.nav > * {
  width: 12rem;
}

.view-only-form-blocker {
  pointer-events: none;
}

/* emergency form specific error handling */
#familySign {
  transition: .75s;
  padding: 0.25rem;
}

#familySign.pulse {
  box-shadow: 0 0 10px 1px rgba(255,0,0,.5);
}

/* alert row for patients requiring follow up */
.ant-table-row.alert, .ant-btn.alert{
  box-shadow: inset 0 0 0px rgba(255, 177, 66, 1);
  animation: pulse-yellow 1.5s infinite;
  border-color: rgba(255, 177, 66, 1);
}

@keyframes pulse-yellow {
  0% {
    /* transform: scale(0.95); */
    box-shadow: inset 0 0 0 rgba(255, 177, 66, 0.7);
  }
  
  70% {
    /* transform: scale(1); */
    box-shadow: inset 0 0 40px rgba(255, 177, 66, 0);
  }
  
  100% {
    /* transform: scale(0.95); */
    box-shadow: inset 0 0 0 rgba(255, 177, 66, 0);
  }
}

.ant-select-tree-title {
    color: black;
    cursor: pointer;
}

.heroImg {
  width: 100%;
  padding: 2rem;
  height: calc(100vh - 53px - 2rem);
  display: grid;
  align-content: center;
  justify-content: center;
}

.heroImg.privacy {
  max-width: 600px;
  height: auto;
}

.heroImg > img {
  width: 100%;
  max-width: 900px;
}

.privacyContainer {
  padding: 2rem !important;
  max-width: 900px;
}

.privacyContainer > h1 {
  font-size: 2rem;
}
.privacyContainer > h2 {
  font-size: 1.85rem;
}
.privacyContainer > h3 {
  font-size: 1.75rem;
}
.privacyContainer > h4 {
  font-size: 1.5rem;
}
.privacyContainer > h5 {
  font-size: 1.25rem;
}